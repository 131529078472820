exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bubblehouse-images-js": () => import("./../../../src/pages/BubblehouseImages.js" /* webpackChunkName: "component---src-pages-bubblehouse-images-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fa-qs-js": () => import("./../../../src/pages/FAQs.js" /* webpackChunkName: "component---src-pages-fa-qs-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/Header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/Imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-more-infos-js": () => import("./../../../src/pages/MoreInfos.js" /* webpackChunkName: "component---src-pages-more-infos-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/Price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/Request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

